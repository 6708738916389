import * as React from 'react';
import type { HeadFC } from 'gatsby';

const ExitPage = () => {
  return <div>You Can Now Exit This page</div>;
};

export const Head: HeadFC = () => <title>Vet Meeting</title>;

export default ExitPage;
